<template>
  <div class="contain">
    <div class="topBanner" :style="{backgroundImage: 'url(' + require('../assets/topbar_bg.png') + ')'}">
      <div class="topBannerContent">
        <div class="topContent">
          <div class="logo">
            <img :src="require('../assets/logo.png')" style="width: 226px; margin-bottom: 70px;" />
          </div>
          <div class="contentText">
            帮瀛第三方资助(TPF)
          </div>
          <div class="contentText">
            为争议标的<b>5000万元以上</b>的诉讼、仲裁当事人
          </div>
          <div class="contentText">
            提供<b>丰沛的资金支持</b>
          </div>
          <div class="contentText">
            及<b>专业的争议解决综合管理服务</b>
          </div>
          <div class="bottomLine"></div>
        </div>
      </div>
    </div>
    <div class="profile">
      <div class="pTitle">PROFILE</div>
      <div class="pDec">
        帮瀛整合型高品质法律服务定制商
      </div>
      <div class="pText">
        帮瀛成立于2015年，是国内首家专业从事民商事诉讼、行政诉讼、刑事诉讼、仲裁等重大纠纷解决的成本投资、律师匹配、项目管理的整合型高品质法律服务定制商。
      </div>
      <div class="pText">
        2017年1月，帮瀛获得由俞敏洪和盛希泰联合创办的洪泰基金领投的A轮融资。
      </div>
      <div class="pText" style="margin-top: 20px">
        <b>帮瀛「诉讼投资」</b>(第三方资助)，将债权争议视为可投资的潜在资产，根本上缓解纠纷解决过程中当事人或律师的成本压力；
        同时，帮瀛为客户加入项目管理服务，帮助客户遴选律师，组建一个法律专业水平高、综合项目管理能力强的跨界服务大团队，
        让客户坐享项目评估、律师遴选、专家论证、模拟法庭、模拟仲裁庭、项目管理、人工智能综合服务，最终实现享受跨专业、跨能力、跨地域、跨阶层的资源整合。
        帮瀛结合自主开发的<b>「多元化纠纷解决数字平台」</b>，能够做到对批量案件进行全线上分流解决，当事人通过线上达成调解协议，并将未主动还款的项目分散约定到全国各地互联网仲裁机构进行速裁，
        生成有效仲裁裁决直接用于银行账户划扣、强制执行，形成司法流水线，实现线上集中、管辖分散的分流处理；<b>「纠纷解决智能平台」</b>，能够做到在极短的时间内，深入分析评估争议事项，
        并根据具体情况迅速匹配最合适的律师、流程化管理诉讼项目，在不同纠纷解决阶段为大型中央企业、商业银行、证券公司、资产管理公司等机构以及个人当事人，提供诉讼投资、项目管理等服务。
      </div>
    </div>
    <div class="timeInfo">
      <div class="timeImg" :style="{backgroundImage: 'url(' + require('../assets/bg2.png') + ')'}">
        <div class="timeText" style="position: relative; top: 170px">
          <div class="timeHead">
            5TH
          </div>
          <div class="timeHead">
            ANNIVERSARY
          </div>
          <div class="timeSub" style="margin: 10px 0">
            帮瀛五周年
          </div>
          <div class="timeSub">
            2015.07.08 —— 2020.07.08
          </div>
          <div class="timeNum">
            <div class="timeNumLeft">
              <div class="timeNumBig">1868<span>个</span></div>
              <div class="timeNumSmall">帮瀛五年收到的有效项目线索</div>
            </div>
            <div class="timeNumRight">
              <div class="timeNumBig">1584.3<span>亿元</span></div>
              <div class="timeNumSmall">五年争议标的总计</div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="statis">
      <div class="statisTop">
        <div style="margin-right: 200px;"><img :src="require('../assets/1.png')"/></div>
        <div><img :src="require('../assets/2.png')"/></div>
      </div>
      <div class="statisBottom">
        <div style="margin-right: 100px; margin-left: 100px;"><img :src="require('../assets/3.png')" /></div>
        <div style="margin-right: 100px"><img :src="require('../assets/4.png')" /></div>
        <div style="flex: 1.5"><img :src="require('../assets/5.png')" /></div>
      </div>
    </div>
    <div class="line">
      <div class="lineHead">
        <div>帮瀛拟投资争议标的额在<b>5000万元</b>以上，</div>
        <div>有<b>胜诉依据</b>，</div>
        <div>并有<b>回款可能性</b>的争议案件。</div>
        <div class="lineCircle"></div>
      </div>
      <div class="lineDec">
        <div style="margin-bottom: 34px">帮瀛诉讼投资流程</div>
        <div style="margin-bottom: 10px">当事人或律师只需提供案件的材料，帮瀛风控部及投管委将根据背景调查及风险评估结果，为项目定制投资方案及管理方案。</div>
        <div style="margin-bottom: 60px"><b>帮瀛投资将覆盖项目的全部或部分成本</b></div>
      </div>
      <div class="lineMode">
        <div>
          <div class="lineActive" style="margin-right: 40px">材料收集</div>
          <div class="numLine" style="border-bottom: 4px solid #002c86">
            <div class="numCircle" style="background: #002c86; left: -20px;">01</div>
          </div>
        </div>
        <div>
          <div class="lineNormal" style="margin: 0 20px">背景调查风险评估</div>
          <div class="numLine">
            <div class="numCircle">02</div>
          </div>
        </div>
        <div>
          <div class="lineNormal" style="margin: 0 20px">项目签约</div>
          <div class="numLine">
            <div class="numCircle">03</div>
          </div>
        </div>
        <div>
          <div class="lineNormal" style="margin-left: 40px">投后管理</div>
          <div class="numLine">
            <div class="numCircle" style="left: 20px">04</div>
          </div>
        </div>
      </div>
    </div>
    <div class="bgModule">
      <div class="bgTitle">
        帮瀛业务领域主要包括：
      </div>
      <div class="bgRow">
        <div class="bgRowLeft">
          国内商事诉讼
        </div>
        <div class="bgRowCenter">
          国内外仲裁
        </div>
        <div class="bgRowRight">
          <div class="bgRt">
            强制执行
          </div>
          <div class="bgRb">
            破产重整
          </div>
        </div>
      </div>
      <div class="bgRow">
        <div class="bgBar">知识产权侵权</div>
        <div class="bgBarNext">反垄断及不正当竞争</div>
      </div>
      <div class="bgRow">
        <div class="bgBar" style="flex: 3">其他重大争议案件</div>
        <div class="bgBarNext" style="flex: 2">特殊资产处置</div>
      </div>
      <div class="bgText">
        <ul>
          <li>帮瀛TPF投资范围将覆盖项目的部分或全部成本，根据背景调查及风险评估结果，帮瀛将为项目定制投资方案。</li>
          <li>帮瀛更倾向于投资处于纠纷解决早期的案件，同等条件下，未诉/未申请仲裁的案件将获得最大限度的投资金额或投资范围。</li>
        </ul>
      </div>
    </div>
    <div class="formInfo">
      <div class="formHead">提交案件信息</div>
      <div class="formContent">
        <form>
          <div class="formRow">
            <div class="formLable">我方名称</div>
            <div class="formInput">
              <input type="text" v-model="formData.myName" maxlength="20"/>
            </div>
          </div>
          <div class="formRow">
            <div class="formLable">对方名称</div>
            <div class="formInput">
              <input type="text" v-model="formData.yourName" maxlength="20"/>
            </div>
          </div>
          <div class="formRow">
            <div class="formLable">纠纷描述</div>
            <div class="formInput" style="margin-right: 20px;">
              <textarea v-model="formData.dec" maxlength="500" style="width: 100%; height: 100px; border: 1px solid #e1e1e1; border-color: #e1e1e1 !important; outline: none; padding: 10px; border-radius: 3px; resize: none"></textarea>
            </div>
          </div>
          <div class="formRow">
            <div class="formLable">争议金额(万元)</div>
            <div class="formInput">
              <input type="text" v-model="formData.money" :class="{'error': state.moneyState}" @input="changeMoney(formData.money)" placeholder="金额不能低于5000万" maxlength="11"/>
            </div>
          </div>
          <div class="formRow">
            <div class="formLable">证据材料</div>
            <div>
              <label id="realBtn" class="btn btn-info">
                <input type="file" name="file" maxlength="32" @change="changeFile" id="fileupload" style="left:-9999px; position:absolute;">
                <span style="
                    font-size: 14px;
                    color: #666;
                    border: 1px solid #ddd;
                    border-radius: 3px;
                    padding: 14px 26px;
                    background: #fff;
                    cursor: pointer;
                  ">请选择文件</span>
              </label>
              <!-- <input type="file" name="file" maxlength="32" @change="changeFile" id="fileupload"/> -->
            </div>
          </div>
          <div class="formRow" v-if="formData.files.length > 0">
            <div class="formLable"></div>
            <div>
              <div v-for="(item, index) in formData.files" :key = index style="color: #666;">
                <span>{{item.file_name}}</span><span style="margin-left: 30px; font-size: 18px; cursor: pointer; padding: 0 10px;" title="删除" @click="deleteFiles(item, index)">×</span>
              </div>
            </div>
          </div>
          <div class="formRow">
            <div class="formLable">联系人</div>
            <div class="formInput">
              <input type="text" v-model="formData.contact" maxlength="20"/>
            </div>
          </div>
          <div class="formRow">
            <div class="formLable">手机号</div>
            <div class="formInput">
              <input type="text" v-model="formData.phone" :class="{'error': state.phoneState}" @input="changePhone(formData.phone)" maxlength="11"/>
            </div>
          </div>
          <div class="formRow">
            <div class="formLable"></div>
            <div>
              <button type="button" class="sub" :class="{'disabledState': (!formData.myName || !formData.yourName || !formData.dec || !formData.money || !formData.fileName || !formData.contact || !formData.phone || state.moneyState || state.phoneState)}" @click="getInfo" :disabled="!formData.myName || !formData.yourName || !formData.dec || !formData.money || !formData.fileName || !formData.contact || !formData.phone || state.moneyState || state.phoneState">提交</button>
            </div>
          </div>
          <div class="formRow" style="position: relative;">
            <div class="formLable"></div>
            <div class="formInput">
              <span style="color: green; font-weight: bold; position: absolute; top: 0; left: 96px;"  v-if="state.subSuccess">提交成功！</span>
              <div style="height: 10px"></div>
              <div class="remark">
                <span style="margin-right: 10px">*</span>帮瀛承诺将对您提交的信息保密；
                <div style="height: 10px"></div>
                <span style="margin-left: 16px">请仔细填写上表内容，稍候会有专人与您联系。</span>
              </div>
            </div>
          </div>
        </form>
      </div>
    </div>
    <div class="bottomInfo">
      Copyright © 2016帮瀛法务 <a href="https://beian.miit.gov.cn/#/Integrated/index" target="_black">京ICP备15051201号-2</a>
      <img src="../assets/icon.png" style="margin-left: 10px; width: 16px; position: relative; top: -1px"/>
      <a href="http://www.beian.gov.cn/portal/registerSystemInfo" style="margin-left: 5px;">京公网安备 11010502043259号</a>
    </div>
  </div>
</template>
<script>
import {onMounted, reactive} from "vue"
import axios from 'axios'
import { setTimeout } from 'timers'
export default {
  name: 'HelloWorld',
  props: {
    msg: String
  },
  setup () {
    const formData = reactive({
      myName: '',
      yourName: '',
      dec: '',
      money: '',
      fileName: '',
      files: [],
      token: '',
      contact: '',
      phone: ''
    })
    const state = reactive({
      phoneState: false,
      moneyState: false,
      subSuccess: false,
      zcCode: ''
    })
    onMounted (() => {
      let href = window.location.href
      let footString = href.split('://')[1]
      let zcStr = footString.split('.')[0]
      state.zcCode = zcStr
      console.log(state.zcCode, '获取二级域名，传给后台')
    })
    const getInfo =  () => {
      if (formData.money < 5000) {
        state.moneyState = true
        return false
      } else if (!(/^1[3456789]\d{9}$/).test(formData.phone)) {
        state.phoneState = true
        return false
      } else {
        state.phoneState = false
        state.moneyState = false
        console.log(formData, '数据')
        // 验证数据是否为空
        submitBtn()
      }
    }
    const changeFile = async (e) => {
      formData.token = await getToken()
      console.log(e, e.target.files[0].name)
      formData.fileName = e.target.files[0].name
      console.log(formData.fileName)
      let fd = new FormData()
      fd.append('file', formData.fileName)
      fd.append('key', formData.fileName)
      fd.append('token', formData.token)
      let config = {
        headers: {
          'Content-Type': 'multipart/form-data'
        }
      }
      // 上传七牛
      axios.post('https://upload.qiniup.com', fd, config).then(res => {
        console.log(res, '上传文件')
        let urlStr = 'https://oldqn.pangu.tech/'
        let arr = formData.files
        arr.push(
          {
            url: urlStr + res.data.key,
            file_name: res.data.key,
            qiniu_file_name: res.data.hash
          }
        )
        formData.files = arr
      }, error => {
        alert(error.error)
      })
    }
    const changePhone = (p) => {
      if ((/^1[3456789]\d{9}$/).test(p)) {
        state.phoneState = false
      } else {
        state.phoneState = true
      }
    }
    const changeMoney = (m) => {
      if (!(/^[0-9]*$/).test(m) || m < 5000) {
        state.moneyState = true
      } else {
        state.moneyState = false
      }
    }
    const getToken = () => {
      return new Promise((resolve, reject) => {
        axios.get('https://api.bangying.org/master/pg_api/index.php/file/upload_token').then(
          res => {
            console.log(res, '获取token')
            resolve(res.data.uptoken)
            formData.token = res.data.uptoken
          }, error => {
            reject(error)
          })
      })
    }
    const submitBtn = () => {
      axios.post('https://api.bangying.org/master/pg_api/index.php/project_case/case', {
        client_name: formData.myName,
        other_name: formData.yourName,
        dispute_describe: formData.dec,
        dispute_money: formData.money,
        file: formData.files,
        contact_name: formData.contact,
        phone: formData.phone,
        entry_type: 1,
        zc_code: state.zcCode
      }).then(res => {
        console.log(res, '提交表单成功')
        if (res.status) {
          formData.myName = ''
          formData.yourName = ''
          formData.dec = ''
          formData.money = ''
          formData.fileName = ''
          formData.token = ''
          formData.contact = ''
          formData.phone = ''
          formData.zc_code = ''
          formData.files = []

          state.subSuccess = true
          setTimeout(() => {
            state.subSuccess = false
          }, 2000)
          // 调取java接口
          let pms = res.data.data
          axios.put('https://www.bangying.tech/pg-api/project/toProject', JSON.parse(pms)).then(res => {
            console.log(res, '成功')
          }, error => {
            console.log(error, '失败')
          })
        }
        let obj = document.getElementById('fileupload')
        obj.value = ''
      })
    }
    const deleteFiles = (item, index) => {
      console.log(item, index)
      axios.put('https://api.bangying.org/master/pg_api/index.php/file/qiniu_delete', {
        qiniu_file_name: item.file_name
      }).then(res => {
        console.log(res)
        formData.files.splice(index, 1)
        if (formData.files.length === 0) {
          let obj = document.getElementById('fileupload')
          obj.value = ''
          formData.fileName = ''
        }
      }, error => {
        console.log(error)
      })
    }
    return {
      getInfo,
      formData,
      changeFile,
      state,
      changePhone,
      deleteFiles,
      changeMoney
    }
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">

.topBanner {
  background-color: #002C86;
  background-repeat: no-repeat;
  background-size: 100% 100%;
  @media screen and (min-width: 1960px){
    background-position-x: 160px;
  }
  position: relative;
}
.topBannerContent {
  overflow: hidden;
}
.contentText {
  font-size: 30px;
  margin-bottom: 15px;
  color: #eee;
  b {
    color: #fff;
  }
}
.topContent {
  text-align: left;
  width: 1000px;
  margin: 140px auto 200px auto;
}
.bottomLine {
  background: #fff;
  width: 48px;
  height: 6px;
  margin-top: 85px;
}
.profile {
  width: 1000px;
  margin: 90px auto;
  text-align: left;
  .pTitle {
    font-size: 60px;
    color: #002C86;
    font-weight: bold;
  }
  .pDec {
    color: #002C86;
    font-weight: bold;
    margin-bottom: 40px;
    font-size: 16px;
  }
  .pText {
    color: #434343;
    line-height: 35px;
    font-size: 16px;
    text-align: justify;
  }
}
.timeInfo {
  height: 520px;
  margin-top: 200px;
  background-color: #002C86;
  width: 100%;
  .timeImg {
    background-repeat: no-repeat;
    background-size: 1067px auto;
    height: 650px;
    @media screen and (min-width: 1960px){
      background-position-x: 160px;
    }
    position: relative;
    top: -130px;
  }
  .timeText {
    width: 1000px;
    text-align: left;
    margin: 0 auto;
    color: #fff;
    .timeHead {
      font-size: 60px;
      font-weight: bold;
    }
    .timeSub {
      font-weight: bold;
      font-size: 24px;
    }
  }
  .timeNum {
    display: flex;
    flex-direction: row;
    align-items: center;
    margin-top: 80px;
    .timeNumLeft {
      width: 350px;
      border-left: 2px solid #fff;
      padding-left: 20px;
    }
    .timeNumRight {
      padding-left: 20px;
      border-left: 2px solid #fff;
    }
    .timeNumBig {
      font-size: 60px;
      font-weight: bold;
      span {
        font-size: 16px;
        font-weight: normal;
        position: relative;
        top: -30px;
        margin-left: 10px;
      }
    }
    .timeNumSmall {
      font-size: 16px;
    }
  }
}
.statis {
  background: url("../assets/bg.png") repeat;
  padding-top: 150px;
  padding-bottom: 10px;
  .statisTop, .statisBottom {
    width: 1000px;
    margin: 0 auto 100px auto;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-content: center;
    div {
      flex: 1;
      align-self: center;
      img {
        width: auto;
        height: auto;
        max-width: 100%;
        max-height: 100%;
      }
    }
  }
}
.line {
  width: 1000px;
  margin: 0 auto;
  .lineHead {
    color: #002C86;
    font-size: 24px;
    margin-top: 100px;
    div {
      margin-bottom: 5px;
    }
    .lineCircle {
      width: 20px;
      height: 20px;
      border: 3px solid #002c86;
      border-radius: 20px;
      margin-top: 40px;
    }
  }
  .lineDec {
    margin-top: 70px;
    color: #434343;
    font-size: 16px;
  }
  .lineMode {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    div {
      flex: 1;
      text-align: center;
    }
    .lineNormal {
      padding: 20px 0px;
      // width: 190px;
      border: 2px solid #b5b5b5;
      font-size: 16px;
      font-weight: 400;
      color: #434343;
      margin: 0 auto;
    }
    .lineActive {
      padding: 20px 0px;
      // width: 190px;
      font-size: 16px;
      font-weight: 700;
      color: #ffffff;
      background: #002c86;
      border: 2px solid #002c86;
      margin: 0 auto;
    }
  }
  .numLine {
    border-bottom: 4px solid #777;
    margin-top: 35px;
  }
  .numCircle {
    width: 30px;
    height: 30px;
    margin: 0 auto;
    background: #777;
    color: #fff;
    font-weight: bold;
    font-size: 14px;
    line-height: 30px;
    text-align: center;
    border-radius: 30px;
    position: relative;
    top: 15px;
  }
}
.bgModule {
  width: 1000px;
  margin: 0 auto;
  text-align: left;
  margin-top: 100px;
  .bgTitle {
    font-size: 16px;
    font-weight: 400;
    color: #434343;
    margin-bottom: 40px;
  }
  .bgRow {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    div {
      flex: 1;
      font-size: 18px;
      color: #fff;
      font-weight: 400;
      text-align: center;
    }
    .bgRowLeft, .bgRowCenter {
      height: 180px;
      line-height: 180px;
      margin-right: 25px;
      background: #002c86;
    }
    .bgRt {
      height: 80px;
      line-height: 80px;
      margin-bottom: 20px;
      background: #002c86;
    }
    .bgRb {
      height: 80px;
      line-height: 80px;
      background: #002c86;
    }
    .bgBar {
      height: 80px;
      line-height: 80px;
      margin-top: 20px;
      background: #002c86;
      margin-right: 20px;
      flex: 2;
    }
    .bgBarNext {
      height: 80px;
      line-height: 80px;
      margin-top: 20px;
      background: #002c86;
      flex: 3
    }
  }
  .bgText {
    margin-top: 50px;
  }
  .bgText ul {
    margin-left: 20px;
  }
  .bgText ul li {
    font-size: 16px;
    font-weight: 400;
    text-align: left;
    color: #626262;
    list-style-type: disc;
    line-height: 36px;
  }
}
.formInfo {
  width: 1000px;
  margin: 0 auto;
  background: #f9f8f8;
  border-radius: 3px;
  padding: 80px 0;
  margin-top: 160px;
  .formHead {
    font-size: 24px;
    font-weight: 700;
    color: #002c86;
    text-align: center;
    margin-bottom: 60px;
  }
  .formRow {
    margin: 0 160px;
    display: flex;
    align-items: center;
    margin-bottom: 20px;
    .formLable {
      width: 80px;
      font-size: 16px;
      font-weight: 400;
      color: #626262;
    }
    .formInput {
      flex: 1;
      input {
        width: 100%;
        height: 50px;
        // line-height: 50px;
        outline: none;
        border: 1px solid #E5E5E5;
        border-radius: 3px;
        padding: 0 10px;
      }
      input[type='number']::-webkit-outer-spin-button,
      input[type='number']::-webkit-inner-spin-button {
        -webkit-appearance: none !important;
        margin: 0
      }
      input[type='number'] {
        -moz-appearance: textfield;
      }
      .error {
        border: 1px solid red;
      }
    }
  }
  .remark {
    font-size: 16px;
    font-weight: 400;
    color: #626262;
    margin-top: 40px;
  }
}
.bottomInfo {
  height: 120px;
  line-height: 120px;
  background-color: #434343;
  margin-top: 100px;
  text-align: center;
  font-size: 14px;
  font-weight: 400;
  color: #ffffff;
  a {
    color: #fff;
    // text-decoration: underline;
    // padding-bottom: 2px;
  }
}
.sub {
  width: 100px;
  height: 50px;
  color: #fff;
  background: #002c86;
  font-size: 16px;
  text-align: center;
  border: none;
  border-radius: 3px;
  outline: none;
  cursor: pointer;
}
.disabledState {
  opacity: 0.5
}
</style>
