<template>
  <div class="home">
    <div class="rightNews">
      <a href="https://mp.weixin.qq.com/s/-lUV9HYQldxHTS2ox2ZLQA" target="_blank"><div class="rightNewItem">帮瀛纠纷解决机构诉讼投资业务(TPF)规则</div></a>
      <a href="http://newstj.bangying.org/" target="_blank"><div class="rightNewItem">诉讼投资与公序良俗</div></a>
    </div>
    <HelloWorld/>
  </div>
</template>

<script>
// @ is an alias to /src
import HelloWorld from '@/components/HelloWorld.vue'

export default {
  name: 'Home',
  components: {
    HelloWorld
  }
}
</script>
<style scoped lang="scss">
a {
  color: #fff;
  text-decoration: none;
}
a:hover {
  text-decoration: none;
}
.rightNews {
  position: fixed;
  top: 400px;
  right: 0;
  z-index: 9;
  .rightNewItem {
    color: #fff;
    background: #0f1ba0;
    padding: 15px 10px;
    font-size: 24px;
    margin-bottom: 15px;
    font-weight: bold;
    box-shadow: -3px 3px 5px #333;
    border-top-left-radius: 10px;
    border-bottom-left-radius: 10px;
    cursor: pointer;
  }
}
</style>
