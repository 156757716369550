<template>
  <div>
    <div id="nav">
      <!-- <router-link to="/">Home</router-link> | -->
      <!-- <router-link to="/about">About</router-link> -->
    </div>
    <router-view/>
  </div>
</template>
<script>
import { onMounted } from 'vue'
import { useRouter } from 'vue-router'
  export default {
    name: 'App',
    setup () {
      const router = useRouter()
      onMounted (() => {
        if (isMobile()) {
        //手机端入口
          router.replace(
            {
              name: 'Phone'
            }
          );
        }
        else {
        //pc端入口文件
          router.replace(
            {
              name: 'Home'
            }
          );
        }
      })
      const isMobile = () => {
        let flag = navigator.userAgent.match(/(phone|pad|pod|iPhone|iPod|ios|iPad|Android|Mobile|BlackBerry|IEMobile|MQQBrowser|JUC|Fennec|wOSBrowser|BrowserNG|WebOS|Symbian|Windows Phone)/i)
        return flag;
      }
      return {
        isMobile
      }
    }
  }
</script>
<style lang="scss">
body,ul,ol,li,p,h1,h2,h3,h4,h5,h6,form,fieldset,table,td,img,div{margin:0;padding:0;border:0;}
body{background:#fff;color:#333;font-size:12px;font-family:"Arial", "SimSun", "宋体";}
body, input, textarea {
  font-family: "Helvetica Neue", sans-serif, Arial, "Microsoft Yahei";
}
ul,ol{list-style-type:none;}
select,input,img,select{vertical-align:middle;}
a{text-decoration:none;}
a:link{color:#009;}
a:visited{color:#800080;}
a:hover,a:active,a:focus{color:#c00;text-decoration:underline;}
input{box-sizing: border-box; font-size: 14px; color: #333}
textarea {font-size: 14px; color:#333}
// *{
//   filter: grayscale(100%);
//   -webkit-filter: grayscale(100%);
//   -moz-filter: grayscale(100%);
//   -ms-filter: grayscale(100%);
//   -o-filter: grayscale(100%);
// }
// img{
//   filter: grayscale(100%);
//   -webkit-filter: grayscale(100%);
//   -moz-filter: grayscale(100%);
//   -ms-filter: grayscale(100%);
//   -o-filter: grayscale(100%);
// }
</style>
